import store from '@/store'
import { computed } from 'vue'
import { get } from 'lodash'

export const useShop = () => {
  const shopId = computed(() => store.getters.shop)
  const shopName = computed(() => store.getters.shopName)
  const shopAddress = computed(() => store.getters.shopAddress)
  const shopPhone = computed(() => store.getters.shopPhone)
  const shopEmail = computed(() => store.getters.shopEmail)
  const shopIndustry = computed(() => get(store.state, 'shop.shopConfig.industry'))
  const shopPointList = computed(() => get(store.state, 'shop.shopSetting.shopPoints'))
  return { shopId, shopName, shopAddress, shopPhone, shopEmail, shopIndustry, shopPointList }
}
