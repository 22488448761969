import Layout from '@/layout'

export const rewardDistributionRoutes = [
  // 會員抽獎模組
  {
    path: '/reward-distribution',
    component: Layout,
    name: 'RewardDistribution',
    alwaysShow: true,
    isGroup: true,
    meta: {
      title: '獎勵發放模組',
    },
    children: [
      {
        path: 'setting',
        name: 'RewardDistributionSetting',
        component: () => import('@/views/RewardDistributionSetting/RewardDistributionSetting'),
        meta: {
          title: '獎勵發放設定',
          action: 'admin.rewardDistribution.page',
        },
      },

      {
        path: 'reward-setting/:activityId',
        name: 'RewardDistributionRewardSetting',
        hidden: true,
        component: () => import('@/views/RewardDistributionRewardSetting/RewardDistributionRewardSetting.vue'),
        meta: {
          title: '獎勵發放 - 獎項管理',
          action: 'admin.rewardDistribution.page',
        },
      },
      {
        path: 'edit/:id?',
        name: 'EditRewardDistribution',
        hidden: true,
        component: () => import('@/views/EditRewardDistribution/EditRewardDistribution.vue'),
        meta: {
          title: '新增獎勵發放',
          action: 'admin.rewardDistribution.create',
        },
      },
      {
        path: 'record',
        name: 'RewardDistributionSettingRecord',
        component: () => import('@/views/RewardDistributionSettingRecord/RewardDistributionSettingRecord.vue'),
        meta: {
          title: '獎勵發放紀錄',
          action: 'admin.rewardDistribution.page',
        },
      },
    ],
  },
]
