<template>
  <div class="lauout">
    <Headbar />
    <div v-loading="!loaded" class="main-view">
      <Sidebar v-if="loaded" :class="{ openSideBar: sidebar }" />
      <div v-if="loaded" id="viewContainer" ref="viewContainer" class="overflow-y-auto w-full border-none" style="padding: 30px">
        <DevOnly v-show="showPageTabs" class="w-full">
          <PageTabs />
        </DevOnly>

        <div v-if="showContractExpiredNotify" class="contractExpired">
          <SVGIcon
            icon="common-notify"
            width="24px"
            height="24px"
            fill="var(--primary-100)"
          />
          您的合約到期日為 {{ expiredDate }}，系統將於合約到期日當天即無法使用，請老師於合約到期前付款完畢並通知小幫手，避免系統自動關閉無法登入唷～
        </div>
        <keep-alive :include="keepAlive">
          <router-view style="padding-bottom: 100px" />
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
import { Sidebar, Headbar } from '@/layout/components'
import { get } from 'lodash'
import { formatDate, addDays } from '@/utils/date'
import dayjs from '@/lib/dayjs'
import { defineComponent, ref, computed, onMounted, watch } from 'vue'
import store from '@/store'
import { useRoute, useRouter } from 'vue-router/composables'
import PageTabs from './components/PageTabs/PageTabs.vue'
import { usePageTabs } from '@/use/usePageTabs'

export default defineComponent({
  name: 'Layout',
  components: {
    Headbar,
    Sidebar,
    PageTabs,
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const loaded = ref(false)
    const sidebar = computed(() => get(store.getters, 'sidebar'))
    const isMobile = computed(() => get(store.state, 'app.isMobile'))
    const keepAlive = computed(() => get(store.state, 'app.keepAlive'))
    const shopConfig = computed(() => get(store.state, 'shop.shopConfig'))
    const sidebarPage = computed(() => get(store.state, 'app.sidebarPage'))
    const expiredDate = computed(() => {
      const endDate = get(shopConfig.value, 'ContractPlan.Contract.end')
      return endDate ? formatDate(endDate, 'YYYY/MM/DD') : '-'
    })
    const showContractExpiredNotify = computed(() => {
      if (['SalesRecordCreateIdentity', 'SalesRecordCreate', 'SalesRecordPayment', 'SalesPOS'].includes(route.name)) return false
      const todayAdd40Days = addDays(new Date(), 40)
      const expiredDate = get(shopConfig.value, 'ContractPlan.Contract.end')
      return dayjs(todayAdd40Days).isAfter(dayjs(expiredDate), 'day') && sidebarPage.value === 2
    })
    const showPageTabs = computed(() => {
      if (['SalesRecordCreateIdentity', 'SalesRecordCreate', 'SalesRecordPayment', 'SalesPOS'].includes(route.name)) return false
      return true
    })
    watch(route, () => {
      if (isMobile.value) store.dispatch('app/setSidebar', false)
      setTimeout(() => {
        const elem = document.getElementById('viewContainer')
        elem.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }, 300)
    })

    router.afterEach((to) => {
      const { addTab } = usePageTabs()
      addTab({
        name: to.name,
        path: to.path,
        params: to.params,
        query: to.query,
        meta: {
          title: to.meta.title,
          keepAlive: to.meta.keepAlive,
        },
      })
    })

    onMounted(() => {
      console.log('🧩layout mounted')
      setTimeout(() => {
        loaded.value = true
      }, 300)
    })
    return {
      loaded,
      sidebar,
      isMobile,
      keepAlive,
      shopConfig,
      sidebarPage,
      expiredDate,
      showContractExpiredNotify,
      showPageTabs,
    }
  },
})
</script>

<style scoped lang="scss">
.lauout {
  @apply bg-[#F7F8FC] flex flex-col h-full;
}

.main-view {
  @apply flex flex-1 overflow-hidden;
}

@media screen and (min-width: 1200px) {
  .openSideBar {
    @apply w-[260px] flex-shrink-0;
    transition: ease-in-out 0.3s;
  }
}

@media screen and (max-width: 1200px) {
  .openSideBar {
    @apply absolute left-0 top-[80px] w-[260px] bottom-0 flex-shrink-0 z-[500];
    transition: ease-in-out 0.3s;
  }
}

// .openSideBar {
//   @apply w-[260px] flex-shrink-0;
//   transition: ease-in-out 0.3s;
// }
.contractExpired {
  @apply px-[12px] py-[8px] pr-[16px] rounded-[4px] border-[1px] border-primary-100 bg-primary-30 mb-[30px];
  @apply grid grid-flow-col gap-[8px] justify-start items-center;
  @apply text-[14px] text-primary-100;
}
</style>
