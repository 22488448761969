import { asyncRoutes, orgSettingsRoutes, constantRoutes } from '@/router'
import { get, filter, cloneDeep } from 'lodash'
/**
 * Use meta.role to determine if the current user has permission
 * @param features
 * @param route
 */
function hasPermission (features, route) {
  if (route.meta && route.meta.action) {
    return checkFeature(features, route.meta.action)
  } else if (route.meta && route.meta.actions) {
    return checkFeature(features, route.meta.actions)
  } else {
    return true
  }
}

/**
 * Use meta.role to determine if the current user has permission
 * @param featureList
 * @param actions
 */
export function checkFeature(featureList, actions) {
  let use = false;

  // 如果 actions 不是數組，將其轉換為數組
  if (!Array.isArray(actions)) {
    actions = [actions];
  }

  for (const action of actions) {
    for (const one of Object.keys(featureList)) {
      if (action === one) {
        use = featureList[one];
      }
      const row = action.split('.');
      while (row.length > 0) {
        row.pop();
        if ([...row, '*'].join('.') === one) use = featureList[one];
      }
    }
  }

  return use;
}
/**
 * Use meta.role to determine if the current user has permission
 * @param shopFeature
 * @param userFeature
 * @param action
 */
export function checkUserFeature (shopFeature, userFeature, action) {
  return checkFeature(shopFeature, action) && checkFeature(userFeature, action)
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param features
 */
export function filterAsyncRoutes (routes, features) {
  const res = []
  routes.forEach((route) => {
    const tmp = { ...route }
    if (hasPermission(features, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, features)
        if (tmp.children.length > 0) res.push(tmp)
      } else {
        res.push(tmp)
      }
    }
  })

  return res
}

const state = {
  routes: [],
  actions: [],
  addRoutes: [],
  orgRoutes: [],

  raw: {
    shopPlanFeatures: {},
    shopPlanLimits: {},
    userFeatures: {},
  },
  computed: {
    shopPlanFeatures: {},
    shopPlanLimits: {},
    userFeatures: {},
  },
  marketingItem: {
    classTicket: false,
    coupon: false,
    point: false,
    cashback: false,
  },
  shopPlanIsReady: false,
}

const mutations = {
  SET_ROUTES: (state, { routes, orgRoutes }) => {
    state.orgRoutes = orgRoutes
    state.addRoutes = routes
    state.routes = constantRoutes.concat(orgRoutes).concat(routes)
  },
  SET_RAW_PERMISSIONS: (state, { key, data }) => {
    state.raw[key] = data
    state.shopPlanIsReady = true
  },
  SET_COMPUTED_PERMISSIONS: (state, { key, data }) => {
    // console.log(key, data)
    state.computed[key] = data
  },
  SET_MARKETINGITEM_PERMISSIONS: (state, { data }) => {
    state.marketingItem = data
  },
}

const actions = {
  generateRoutes ({ commit, rootState }, { features, shopPlan }) {
    return new Promise((resolve) => {
      const {
        shopPlanFeature,
      } = shopPlan

      const enableOrgExternalTransaction = get(rootState, 'org.org.DashboardConfig.orgModulePermission.enableOrgExternalTransaction')
      const enableOrgBeautyAI = get(rootState, 'org.org.DashboardConfig.orgModulePermission.enableOrgBeautyAI')
      const enableOrgBusinessDataChart = get(rootState, 'org.org.DashboardConfig.orgModulePermission.enableOrgBusinessDataChart')

      if (shopPlanFeature) {
        const shopRoutes = filterAsyncRoutes(asyncRoutes(), shopPlanFeature)
        const routes = filterAsyncRoutes(shopRoutes, features)

        let cloneOrgSettingsRoutes = cloneDeep(orgSettingsRoutes)
        if (!enableOrgExternalTransaction) {
          cloneOrgSettingsRoutes = filter(cloneOrgSettingsRoutes, (route) => route.path !== '/all-shop-external-transaction-record-list')
        }
        if (!enableOrgBeautyAI) {
          cloneOrgSettingsRoutes = filter(cloneOrgSettingsRoutes, (route) => route.name !== 'OrgAIAnalysis')
        }
        if (!enableOrgBusinessDataChart) {
          cloneOrgSettingsRoutes = filter(cloneOrgSettingsRoutes, (route) => route.name !== 'OrgOperateDashboard')
        }

        const orgRoutes = filterAsyncRoutes(cloneOrgSettingsRoutes, features)

        commit('SET_ROUTES', { routes, orgRoutes })
        resolve(orgRoutes.concat(routes))
      } else {
        let cloneOrgSettingsRoutes = cloneDeep(orgSettingsRoutes)
        if (!enableOrgExternalTransaction) {
          cloneOrgSettingsRoutes = filter(cloneOrgSettingsRoutes, (route) => route.path !== '/all-shop-external-transaction-record-list')
        }
        if (!enableOrgBeautyAI) {
          cloneOrgSettingsRoutes = filter(cloneOrgSettingsRoutes, (route) => route.name !== 'OrgAIAnalysis')
        }
        if (!enableOrgBusinessDataChart) {
          cloneOrgSettingsRoutes = filter(cloneOrgSettingsRoutes, (route) => route.name !== 'OrgOperateDashboard')
        }
        const orgRoutes = filterAsyncRoutes(cloneOrgSettingsRoutes, features)
        commit('SET_ROUTES', { orgRoutes })
      }
    })
  },
  // eslint-disable-next-line no-empty-pattern
  checkUserFeature ({}, { userFeatures, action }) {
    return new Promise((resolve) =>
      resolve(checkFeature(userFeatures, action)),
    )
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
