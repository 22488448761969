import Layout from '@/layout'

export const salesRoutes = [
  // 銷售紀錄模組
  {
    path: '/sales',
    component: Layout,
    alwaysShow: true,
    isGroup: true,
    meta: {
      title: '銷售紀錄模組',
    },
    children: [
      {
        path: 'settings',
        component: {
          render (c) {
            return c('router-view')
          },
        },
        meta: {
          title: '銷售紀錄管理',
        },
        children: [
          {
            path: 'sales-category-setting',
            name: 'SalesCategorySetting',
            component: () => import('@/views/Sales/SalesCategorySetting'),
            meta: {
              title: '銷售類別設定',
              action: 'admin.salesCategory.page',
              bobPageAction: 'PAGE_VIEW_DASH_SHOP_SALE_CATEGORY_LIST',
            },
          },
          {
            path: 'sales-product-setting',
            name: 'SalesProductSetting',
            component: () => import('@/views/Sales/SalesProductSetting'),
            meta: {
              title: '銷售產品設定',
              action: 'admin.salesProduct.page',
              bobPageAction: 'PAGE_VIEW_DASH_SHOP_SALE_PRODUCT_LIST',
            },
          },
          {
            path: 'sales-unit-setting',
            name: 'SalesUnitSetting',
            component: () => import('@/views/Sales/SalesUnitSetting'),
            meta: {
              title: '銷售人員設定',
              action: 'admin.salesUnit.page',
              bobPageAction: 'PAGE_VIEW_DASH_SHOP_SALE_UNIT_LIST',
            },
          },
          {
            path: 'sales-discount-setting',
            name: 'SalesDiscountSetting',
            component: () => import('@/views/Sales/SalesDiscountSetting'),
            meta: {
              title: '銷售優惠設定',
              action: 'admin.salesDiscount.page',
              bobPageAction: 'PAGE_VIEW_DASH_SHOP_SALE_DISCOUNT_LIST',
            },
          },
          {
            path: 'sales-group-setting',
            name: 'SalesGroupSetting',
            component: () => import('@/views/Sales/SalesGroupSetting'),
            meta: {
              title: '銷售群組設定',
              action: 'admin.salesGroup.page',
              bobPageAction: 'PAGE_VIEW_DASH_SHOP_SALE_GROUP_LIST',
            },
          },
        ],
      },
      {
        path: 'sales-record-report',
        name: 'SalesRecordReport',
        component: () => import('@/views/Sales/SalesReport/SalesReport.vue'),
        meta: {
          title: '銷售報表',
          action: 'admin.salesReport.page',
          bobPageAction: 'PAGE_VIEW_DASH_SHOP_SALE_REPORT',
        },
      },
      {
        path: 'sales-record-list',
        name: 'SalesRecordList',
        component: () =>
          import('@/views/Sales/SalesRecordList/SalesRecordList.vue'),
        meta: {
          title: '銷售紀錄列表',
          action: 'admin.salesRecord.page',
          bobPageAction: 'PAGE_VIEW_DASH_SHOP_SALE_RECORD_LIST',
        },
      },
      // {
      //     path: 'sales-record-login',
      //     name: 'SalesRecordLogin',
      //     component: () => import('@/views/Sales/SalesRecordLogin'),
      //     meta: {
      //         title: '建立銷售紀錄',
      //         action: 'admin.salesRecord.create'
      //     },
      // },
      {
        // 輸入手機號碼頁
        path: 'sales-record-login',
        name: 'SalesRecordLogin',
        component: () => import('@/views/Sales/SalesRecordLogin'),
        meta: {
          title: '建立銷售紀錄',
          action: 'admin.salesRecord.create',
        },
      },
      {
        // 輸入手機號碼頁
        path: 'sales-record-login-cross-day',
        name: 'SalesRecordLoginCrossDay',
        component: () => import('@/views/Sales/SalesRecordLogin'),
        meta: {
          title: '建立跨日銷售紀錄',
          action: 'admin.salesRecord.pastPage',
          specifyDate: true,
        },
      },
      {
        // 身分別建立銷售紀錄
        path: 'sales-record-create/identity',
        name: 'SalesRecordCreateIdentity',
        hidden: true,
        component: () =>
          import('@/views/Sales/CreateSalesRecord/SalesRecordCreate.vue'),
        meta: {
          title: '建立銷售紀錄',
          type: 'identity',
          action: 'admin.salesRecord.create',
          bobPageAction: 'PAGE_VIEW_DASH_SHOP_SALE_RECORD_CREATE',
        },
      },
      {
        // 會員建立銷售紀錄
        path: 'sales-record-create/:id/:isMember?',
        name: 'SalesRecordCreate',
        hidden: true,
        component: () =>
          import('@/views/Sales/CreateSalesRecord/SalesRecordCreate.vue'),
        meta: {
          title: '建立銷售紀錄',
          type: 'create',
          action: 'admin.salesRecord.create',
          bobPageAction: 'PAGE_VIEW_DASH_SHOP_SALE_RECORD_CREATE',
        },
      },
      {
        // 付款頁
        path: 'sales-record-payment',
        name: 'SalesRecordPayment',
        component: () =>
          import('@/views/Sales/CreateSalesRecord/SalesRecordCreate.vue'),
        hidden: true,
        meta: {
          title: '建立銷售紀錄',
          type: 'payment',
          action: 'admin.salesRecord.create',
        },
      },
      {
        path: 'sales-activity',
        name: 'SalesActivity',
        component: () =>
          import('@/views/Sales/SalesActivityRecord/SalesActivityRecord.vue'),
        meta: {
          title: '銷售回饋活動',
          action: 'admin.salesRewardActivity.page',
        },
      },
      {
        path: 'sales-activity/:id?',
        name: 'SalesActivityEdit',
        component: () =>
          import('@/views/Sales/SalesActivityEdit/SalesActivityEdit.vue'),
        hidden: true,
        meta: {
          action: 'admin.salesRewardActivity.page',
        },
      },
      {
        path: 'manual-sales-record/links',
        name: 'ManualSalesRecordLinks',
        component: () =>
          import(
            '@/views/Sales/ManualSalesRecordLinks/ManualSalesRecordLinks.vue'
          ),
        meta: {
          title: '消費登錄連結',
          action: 'admin.salesClientLink.create',
        },
      },
      // {
      //   name: 'SalesPOSCreate',
      //   path: 'sales-pos-create',
      //   redirect: '/sales-pos/entry',
      //   meta: {
      //     title: '銷售POS',
      //     action: 'admin.salesRecord.create',
      //   },
      // },
    ],
  },
  // {
  //   name: 'SalesPOS',
  //   path: '/sales-pos',
  //   hidden: true,
  //   component: () => import('@/layout/Fullpage.vue'),
  //   children: [
  //     {
  //       name: 'SalesPOSEntry',
  //       path: 'entry',
  //       hidden: true,
  //       component: () => import('@/views/SalesPOSEntry/SalesPOSEntry.vue'),
  //       meta: {
  //         title: '銷售POS',
  //         action: 'admin.salesRecord.create',
  //       },
  //     },
  //     {
  //       name: 'SalesPOS',
  //       path: 'create',
  //       hidden: true,
  //       component: () => import('@/views/SalesPOS/SalesPOS.vue'),
  //       meta: {
  //         title: '銷售POS',
  //         action: 'admin.salesRecord.create',
  //       },
  //     },
  //     {
  //       name: 'SalesPOSCheckout',
  //       path: 'checkout',
  //       hidden: true,
  //       component: () => import('@/views/SalesPOSCheckout/SalesPOSCheckout.vue'),
  //       meta: {
  //         title: '銷售POS結帳',
  //         action: 'admin.salesRecord.create',
  //       },
  //     },
  //   ],
  // },
]
